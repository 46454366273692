<template>
  <section class="container my-24 my-lg-32">
    <div class="row">
      <header class="col">
        <b>Страница 1 из 15</b>
      </header>
    </div>

    <section class="row mt-8">
      <div class="col">
        <v-card class="py-32 px-16 p-xl-32">
          <div class="row">
            <div class="col">
              <h2>Что необходимо поменять в компании?</h2>

              <p>
                Ниже в центральном окне список практик и процессов компании. <b>Ваша задача — оценить, какие из них
                требуют доработки и изменений в приоритетном порядке, а какие — нет</b>.
              </p>

              <p>
                Для этого переместите <b>5 наиболее приоритетных процессов и практик направо</b> и <b>5 наименее
                приоритетных практик — налево</b>. Перетащить характеристики можно мышкой, либо кнопкой со стрелкой.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <drag-ranging-chebur
                :variants="variant"
                :max="5"
              />
            </div>
          </div>

          <div class="row mt-40">
            <div class="col">
              <v-button>Ответить</v-button>
            </div>
          </div>
        </v-card>
      </div>
    </section>
  </section>
</template>

<script>
import VCard from '@components/base/VCard'
import { DragRangingChebur } from '@components/features/DragRaging'
import VButton from '@components/base/VButton'

export default {
  name: 'S360Chebur',

  components: {
    VButton,
    DragRangingChebur,
    VCard
  },

  data: () => ({
    variant: [
      { id: 1, text: 'Условия труда (рабочее место, общественные помещения)' },
      { id: 2, text: 'Содержание работы (соответствие моим знаниям, навыкам и интересам' },
      { id: 3, text: 'Качество взаимодействия между подразделениями' },
      { id: 4, text: 'Постановка задач и обратная связь от непосредственного руководителя' },
      { id: 5, text: 'Работа с инициативами и идеями сотрудников' },
      {
        id: 6,
        text: 'Карьерные возможности (повышение разряда, участие в программах кадрового резерва, лидерство в изменениях, экспертная карьера, волонтерство и др.)'
      },
      { id: 7, text: 'Объем полномочий для решения поставленных задач' },
      {
        id: 8,
        text: 'Признание достижений сотрудников (например, похвала, одобрение, благодарность, размещение на доске почета и т.д.)'
      },
      { id: 9, text: 'Система постановки целей в компании' }
    ]
  })
}
</script>
